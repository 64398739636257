<template>
    <div>
      <div class="wdswbg"></div>
      <div class="flex cbg">
        <div class="flex-1"></div>
        <div class="center">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/personindex' }">个人主页</el-breadcrumb-item>
            <el-breadcrumb-item>我的事务</el-breadcrumb-item>
          </el-breadcrumb>
          <div   class="table">
            <div class="title">关联企业</div>
            <div class="sx">
              <formlist class="flex fll" :arr="shuaixuan">
                 <el-button type="primary" size="mini" style="margin: 8px 0 0 8px">筛选</el-button>
                 <el-button type="" size="mini" style="margin: 8px 0 0 8px">重置</el-button>
              </formlist>
              <template>
                <el-table
                    :data="tableData"
                    style="width: 100%;margin-top: 36px;">
                  <el-table-column
                      prop="type"
                      label="消息类型"
                      align="center"
                      width="130">
                  </el-table-column>
                  <el-table-column
                      prop="title"
                      align="center"
                      label="标题">
                  </el-table-column>
                  <el-table-column
                      prop="time"
                      align="center"
                      label="发送时间"
                      width="250">
                  </el-table-column>
                </el-table>
              </template>
              <div class="fenye">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    page-size="15"
                    :total="total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1"></div>
      </div>
    </div>
</template>

<script>
import formlist  from "../../components/formlist";
export default {
  name: "wdsw",
  data(){
    return {
      tableData:[{
        type:'个人消息',
        title:'标题1111111111111',
        time:'2023-12-12'
      }],
      pageNo:1,
      total:1,
      shuaixuan:[{
        type:'select',
        inputTitle:'个人信息',
        options:[{
          optionTitle:'111',
          inputValue:'222'
        }]
      },{
        type:'date',
        inputTitle:'日期范围',
        inputName:'time1|time2'
      }]
    }
  },
  methods:{
    // handleSizeChange(val) {
    //   this.pageSize=val
    //   this.loadlist()
    // },
    handleCurrentChange(val) {
      this.pageNo=val
      this.loadlist()
    },
    loadlist(){

    }
  },
  components:{
    formlist
  }
}
</script>

<style lang="scss" scoped>
.fenye{
  text-align: center;
  padding: 30px 0;
}
    .wdswbg{
      width: 100%;
      height: 150px;
      background: url("../../assets/home/wdsw1.png") no-repeat;
      background-size: 100% 100%;
    }
    .cbg{
      width: 100%;
      background: url("../../assets/home/cbg.png") no-repeat;
      background-size: 100% 100%;
      height: calc( 100vh - 85px );
    }
    .center{
      width: 1200px;
    }
    .mbx{
      margin:50px 0 34px 0;
    }
    .table{
      background: rgba(255,255,255,0.7);
      border-radius: 4px;
      border: 1px solid #E5E5E5;
      .title{
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        padding: 17px;
        border-bottom: 1px solid #E5E5E5;
      }
      .sx{
        padding: 17px;
      }
    }
</style>