<template>
    <div>
        <div v-for="(item,index) in arr" class="list" :key="index">
            <div class="marginr" v-if="item.type=='text'">
                <label class="label"> {{item.inputTitle}}</label>
                <el-input v-model="item.value" :placeholder="item.placeholder?item.placeholder:'请输入'"></el-input>
            </div>
            <div class="marginr" v-if="item.type=='number'">
                <label class="label"> {{item.inputTitle}}</label>
                <el-input v-model="item.value" type="number" :placeholder="item.placeholder?item.placeholder:'请输入'"></el-input>
            </div>
            <div class="marginr flex" v-if="item.type=='textarea'">
                <label class="label">{{item.inputTitle}}</label>
                <el-input v-model="item.value" class="flex-1" type="textarea" :placeholder="item.placeholder?item.placeholder:'请输入'"></el-input>
            </div>
            <div class="marginr" v-if="item.type=='select'">
                <label class="label">{{item.inputTitle}}</label>
                <el-select v-model="item.value" clearable  @change="selectchange(item)"  :placeholder="item.placeholder?item.placeholder:'请选择'">
                    <el-option
                            v-for="list in item.options"
                            :key="list.inputValue"
                            :label="list.optionTitle"
                            :value="list.inputValue">
                    </el-option>
                </el-select>
            </div>
            <div class="marginr" v-if="item.type=='company'">
                <label class="label">{{item.inputTitle}}</label>
                <el-select
                        v-model="item.value"
                        filterable
                        remote
                        clearable
                        reserve-keyword
                        placeholder="请输入"
                        :remote-method="(query)=>{remoteMethod(query,item)}"
                        >
                    <el-option
                            v-for="list in item.options"
                            :key="list.inputValue"
                            :label="list.optionTitle"
                            :value="list.inputValue">
                    </el-option>
                </el-select>
            </div>
            <div class="marginr" v-if="item.type=='radio'">
                <label class="label">{{item.inputTitle}}</label>
                <el-radio-group v-model="item.value">
                    <el-radio :label="list.optionTitle"  v-for="(list,index) in item.options" :key="index"></el-radio>
                </el-radio-group>
            </div>
            <div class="marginr" v-if="item.type=='checkbox'">
                <el-checkbox-group v-model="item.value">
                    <el-checkbox :label="list.optionTitle" :name="list.inputValue"></el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="marginr" v-if="item.type=='date'&&(item.inputName.split('|')).length<2">
                <label class="label">{{item.inputTitle?item.inputTitle:'选择日期'}}</label>
                <el-date-picker
                        v-model="item.value"
                        type="date"
                        format="yyyy-MM-dd"
                        :clearable="false"
                        placeholder="选择日期">
                </el-date-picker>
            </div>
            <div class="marginr" v-if="item.type=='date'&&(item.inputName.split('|')).length>=2">
                <label class="label"> {{item.inputTitle?item.inputTitle:'起始日期'}}</label>
                <el-date-picker
                        class="riqi"
                        v-model="item.value"
                        type="datetimerange"
                        :picker-options="pickerOptions2"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        :clearable="false"
                        @change="change"
                        align="right"
                        >
                </el-date-picker>
            </div>
        </div>
      <div>
        <slot></slot>
      </div>
    </div>
</template>

<script>
    export default {
        name: "formlist",
        props:['arr','choosemonth'],
        data(){
            return {
                pickerOptions2: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }],
                    disabledDate:this.disabledDate,
                    onPick:this.onPick
                },
                arr1:[{
                    type:'text',
                    text:'人',
                    value:''
                },{
                    type:'select',
                    select:[{
                        key:'hehe',
                        value:'11'
                    }],
                    value:''
                },{
                    type:'date',
                    value:''
                },{
                    type:'date1',
                    value1:'',
                    value2:''
                }],
                times:'',
                timer:null
            }
        },
        methods:{
            remoteMethod(query,item){
                if (query !== '') {
                    clearTimeout(this.timer)
                    this.timer = setTimeout(()=>{
                        this.$store.dispatch('companySearch', {
                            "keyWord": query,
                            "groupModel":'group'
                        }).then(res => {
                            const arr=[]
                            for (const val of res) {
                                arr.push({
                                    inputValue:val.cid,
                                    optionTitle:val.cnamecn
                                })
                            }
                            this.$set(item,'options',arr)
                        })
                    }, 300)
                } else {
                    this.$set(item,'options',[])
                }
            },
            disabledDate(time){
                    if(this.times&&this.choosemonth){
                        let curDate = (new Date(this.times)).getTime();
                        let three = (parseInt(this.choosemonth)*30) * 24 * 3600 * 1000;
                        let threeMonths = curDate - three;
                        let threeMonths1 = curDate + three;
                        return time.getTime() > threeMonths1 || time.getTime() < threeMonths;

                    }
               },
            onPick(str1){
                if(str1.maxDate){
                    this.times=''
                }else {
                    this.times=str1.minDate
                }
            },
            change(val){
                if(!val){
                    this.times=''
                }
            },
            selectchange(item){
                this.$emit('selectchange',item)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .marginr{
        margin-right: 45px;
    }
    .el-input{
        width: auto!important;
    }
    .label{
        //min-width: 85px;
        display: inline-block;
        //text-align: justify;
        //text-align-last: justify;
        margin-right: 10px;
    }
    .list{
        display: inline-block;
        margin: 5px 0;
    }
</style>
